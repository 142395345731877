import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'correctTime'
})
export class CorrectTimePipe implements PipeTransform {

    transform(value: string, hours : number, isAdd : boolean) {
        
        if(!value) return "";

        const momentValue = moment(value);

        return isAdd ? momentValue.add(hours,'hours').format("DD.MM.yyyy HH:mm:ss") : momentValue.subtract(hours,'hours').format("DD.MM.yyyy HH:mm:ss");
    }

}