import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/models/auth/user';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {
  
  constructor(public _authenticationService: AuthenticationService,
            public _http: HttpClient) {
      super(_authenticationService, _http, environment.user,environment.userSelect);
  }

  getListForFilter(filter: any, offset: number, maxSize: number, selectedUserType: string) {
    let whereQuery = "";
    let whereCounter = 0;

    if(selectedUserType != "Tümü"){
        whereQuery += `${whereCounter == 0 ? "" : "&"}where[${whereCounter}][type]=in&where[${whereCounter}][attribute]=kullancTipi&where[${whereCounter}][value][]=${selectedUserType}`;
        whereCounter++;
    }

    //FURKAN Burası düzenlenmeli filtrelemeye göre
    if (Object.keys(filter).some(k => !!filter[k])) {
        let search = filter['search'];
  
        if (search && search != '') {
          whereQuery += `${whereCounter == 0 ? "" : "&"}where[${whereCounter}][type]=textFilter&where[${whereCounter}][value]=%25${search}%25`;
          whereCounter++;
        }
    }
    

    return this.getListWithSelect(whereQuery,offset,maxSize);
  }

}