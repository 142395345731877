import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input() item: any;
  
  @Input() itemSelected = new EventEmitter<boolean>()
  /**
   *
   */
  constructor() {
    
  }

}
