import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  // {
  //   id: 'dashboard',
  //   title: 'Anasayfa',
  //   translate: 'Anasayfa',
  //   type: 'item',
  //   icon: 'home',
  //   url: 'anasayfa',
  //   auth : ["Firma", "Onaysız Firma", "Vatandaş"]

  // },

  // {
  //   id: 'yonetici',
  //   title: 'Yönetici',
  //   translate: 'MENU.DASHBOARD.COLLAPSIBLE',
  //   type: 'collapsible',
  //   icon: 'home',

  //   children:[
  //     {
  //       id: 'projects-yonetici',
  //       title: 'Projeler',
  //       translate: 'MENU.PROJECTS',
  //       type: 'item',
  //       icon: 'package',
  //       url: 'dashboard/proje',
  //   },
  //   {
  //     id: 'maindashboard',
  //     title: 'Dashboard',
  //     translate: 'MENU.DASHBOARD',
  //     type: 'item',
  //     icon: 'pie-chart',
  //     url: 'dashboard/main-dashboard',
  // }]}
    
];
