import {EtiketService} from './../../../services/etiket.service';
import {Etiket} from './../../../models/entity/etiket';
import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-etiket-card',
    templateUrl: './etiket-card.component.html',
    styleUrls: ['./etiket-card.component.scss'],

})
export class EtiketCardComponent implements OnInit {

    @Input() etiket: Etiket;
    envire: any;

    constructor(public _etiketService: EtiketService) {
        this.envire = environment;
    }

    ngOnInit(): void {
    }


}
