import {Component, ViewEncapsulation} from '@angular/core';
import {Route, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {EtiketService} from '../../../services/etiket.service';
import {Api} from '../../../services/api';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'content',
    styleUrls: ['./content.component.scss'],
    templateUrl: './content.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ContentComponent {
    /**
     * Constructor
     */


    constructor() {
    }

}
