import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class EncDecrService {
    constructor() {}

    set(value){
        const passphrase = '12345';
        return CryptoJS.AES.encrypt(value, passphrase).toString();
    }

      //The get method is use for decrypt the value.
    get(value){
        if(!value) return null;
        const passphrase = '12345';
        const bytes = CryptoJS.AES.decrypt(value, passphrase);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
