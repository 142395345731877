import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'currencySign'
})
export class CurrencySignPipe implements PipeTransform {

  private currencySignObject = {
    "TRY" : "₺",
    "USD" : "$",
    "EUR" : "€",
    "GBP" : "£"
  }

  transform(currency : string): string {
    
    return this.currencySignObject[currency];
  }

  
}