import {OnInit, OnDestroy, Component} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CoreConfigService} from '@core/services/config.service';
import {UtilsService} from 'app/services/utilsService.service';
import {Api} from '../../../services/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],

})
export class FooterComponent implements OnInit, OnDestroy {
    public coreConfig: any;
    public year: number = new Date().getFullYear();

    // Private
    private _unsubscribeAll: Subject<any>;
    linkss: any = [];

    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     */
    sugges: any = {
        email: '', isim: '', description: '', userId: '', assignedUserId: '', konu: ''
    };

    usr: any = {};

    constructor(public _coreConfigService: CoreConfigService, private _modalService: NgbModal,
                private _utilsService: UtilsService, private apis: Api, private auth: AuthenticationService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.getLinks();
        this.usr = this.auth.getCurrentUser;
        console.log('------>', this.usr);
    }
    save(mod) {
        if (this.sugges.description == '') {
            this._utilsService.showWarning('Bir açıklama giriniz!');
            return;
        }
        if (this.sugges.isim == '') {
            this._utilsService.showWarning('Lütfen isminizi giriniz!');
            return;
        }
        if (this.sugges.konu == '') {
            this._utilsService.showWarning('Bir konu bilgisi giriniz!');
            return;
        }
        this.usr = this.auth.getCurrentUser;
        this.apis.post('Suggestion', {
            description: this.sugges.description,
            email: this.sugges.email,
            konu: this.sugges.konu,
            isim: this.sugges.isim,
            assignedUserId: this.usr.id,
            userId: this.usr.id
        }).subscribe(data => {
            this._utilsService.showSuccess('Görüş-Önerileriniz tarafımıza iletilmiştir.  Teşekkürler!');
            this.sugges = {
                name: '', isim: '', description: '', userId: '', assignedUserId: '', konu: ''
            };
            mod.close();
        }, error => {
            this._utilsService.showError('Bir hata oluştu daha sonra tekrar deneyiniz!');
        });

    }

    closeModal(mod) {
        mod.close();
    }

    goPage(item) {
        console.log('asdasd');
        window.open(item.urll, '_blank');
    }

    getLinks() {
        this.apis.defaultGetList('Link', {
            select: 'sirano,name,urll',
            maxSize: '20',
            offset: '0',
            orderBy: 'sirano',
            order: 'asc'
        }).subscribe(data => {
            this.linkss = data.list;
        });
    }

    csugges(mod) {
        this.usr = this.auth.getCurrentUser;
        console.log(this.usr);
        console.log(this.auth.getCurrentUser);
        this._modalService.open(mod, {
            centered: false,
            size: 'xpp',
            backdrop: 'static',
        });
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    opentab(link) {
        window.open(link, '_blank');
    }

    openkisiselVeriPolitikamizModal(kisiselVeriPolitikamizModal: any) {
        this._utilsService.openModal(kisiselVeriPolitikamizModal, {
            size: 'lg',
            backdrop: 'static',
            centered: true,
        });
    }

    closekisiselVeriPolitikamizModal(modal: any) {
        this._utilsService.closeModal(modal);
    }

    openbilgiGuvenligiPolitikamizModal(bilgiGuvenligiPolitikamizModal: any) {
        this._utilsService.openModal(bilgiGuvenligiPolitikamizModal, {
            size: 'lg',
            backdrop: 'static',
            centered: true,
        });
    }

    closebilgiGuvenligiPolitikamizModal(modal: any) {
        this._utilsService.closeModal(modal);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
