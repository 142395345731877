import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import * as uuid from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import * as localeEn from 'app/menu/i18n/en';
import * as localeTr from 'app/menu/i18n/tr';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import Swal from 'sweetalert2';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Injectable({providedIn: 'root'})
export class UtilsService {

    @BlockUI() protected _blockUi: NgBlockUI;

    constructor(private _modalService: NgbModal,
                private _toastrService: ToastrService,
                private _translateService: TranslateService,
                private _coreSidebarService: CoreSidebarService,
                private _componentFactoryResolver: ComponentFactoryResolver,
                private _datePipe: DatePipe) {


    }

//   toggleSidebar(options : any){

//     const componentFactoryRes = this._componentFactoryResolver.resolveComponentFactory(MaintenanceSidebarComponent);
//     options.viewContainerRef.clear();
//     // @ts-ignore
//     const dynamicComponent =  options.viewContainerRef.createComponent<any>(componentFactoryRes);
//     dynamicComponent.instance.options = options;

//     setTimeout(() => {
//       this._coreSidebarService.getSidebarRegistry("forms").toggleOpen();
//     }, 200);
//   }

    getNow(takeTimePart: boolean = true) {
        return this._datePipe.transform(new Date(), takeTimePart ? 'yyyy-MM-ddTHH:mm:ss' : 'yyyy-MM-dd');
    }

    openModal(modal, options?, callbackToRoot?): NgbModalRef {
        const modalRef = this._modalService.open(modal, {
            size: options?.size || 'xl',
            centered: options?.centered || false,
            backdrop: 'static',
            keyboard: false
        });

        if (options && modalRef.componentInstance) {
            modalRef.componentInstance.options = options;
        }

        if (callbackToRoot) {
            modalRef.componentInstance.callbackToRoot.subscribe(x => {
                callbackToRoot();
            });
        }

        return modalRef;
    }

    closeModal(modal) {
        modal.close();
    }

    showSuccess(message: string, title: string = 'Başarılı.') {
        this._toastrService.success(message, title, {toastClass: 'toast ngx-toastr', closeButton: true});
    }

    showInfo(message: string, title: string = 'Bilgilendirme...') {
        this._toastrService.info(message, title, {toastClass: 'toast ngx-toastr', closeButton: true});
    }

    showWarning(message: string, title: string = 'Uyarı!!') {
        this._toastrService.warning(message, title, {toastClass: 'toast ngx-toastr', closeButton: true});
    }

    showError(message: string, title: string = 'Hata!!', error?) {
        this._toastrService.error(message, title, {toastClass: 'toast ngx-toastr', closeButton: true});
        if (error) {
            //console.log(error.message);
        }
    }

    startLoading(message: string) {
        this._blockUi.start(message);
    }

    stopLoading() {
        this._blockUi.stop();
    }

//   exportExcel(exportList : any[],fileName : string){
//     this._excelExportService.exportData(exportList,fileName);
//   }

    getObjectNameValueFromReportData(data: any, count?: number) {

        let groupKey = data.groupByList[0];
        //  let groupKey = data.groupBy[0];
        let groupValueMap = data.groupValueMap[groupKey];
        let keys = !!groupValueMap ? Object.keys(groupValueMap) : data.grouping[0];
        let valueKey = data.columnList[0];
        let result = [];
        keys.forEach(key => {
            let correctKey = !!groupValueMap ? groupValueMap[key] : key;
            result.push({id: key, value: data.reportData[key][valueKey], name: groupValueMap[key]});
        });

        if (count) {
            result = this.getTopNValueFromObject(result, count);
        }

        return result;
    }

    getKeyValuePairFromReportData(data: any, count?: number) {

        let groupKey = data.groupByList[0];
        //  let groupKey = data.groupBy[0];

        let keys = !!data.groupValueMap[groupKey] ? Object.keys(data.groupValueMap[groupKey]) : data.grouping[0];
        let valueKey = data.columnList[0];
        let result = {};
        keys.forEach(key => {
            let correctKey = !!data.groupValueMap[groupKey] ? data.groupValueMap[groupKey][key] : key;
            result[correctKey] = data.reportData[key][valueKey];
        });

        if (count) {
            result = this.getTopNValueFromObject(result, count);
        }

        return result;
    }

    getTotalFromGridReportData(data: any) {
        let valueKey = data.columnList[0];
        return data.sums[valueKey];
    }

    getTopNValueFromObject(obj: any, n: number) {
        let values = Object.values(obj).sort(r => Number(r));
        let entries: any = Object.entries(obj)
            .sort((r1, r2) => Number(r2[1]) - Number(r1[1]))
            .slice(0, Math.min(n, values.length))
            .reduce((acc, [k, v]) => (
                acc[k] = v,
                    acc
            ), {});

        return entries;
    }

    getUuid(): string {
        return uuid.v4();
    }

    getRandomString(length: number) {
        var result = '';
        // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

//   getModuleList() : LabelValuePair[]{

//     let locale = this._translateService.currentLang == 'en' ? localeEn : localeTr;
//     const moduleKeys = Object.keys(locale.locale.data.MODULES.VALUES);
//     let moduleList : LabelValuePair[] = [];
//     moduleKeys.forEach(moduleKey => {
//       moduleList.push(
//         { label : locale.locale.data.MODULES.VALUES[moduleKey] , value : moduleKey}
//       )
//     });

//     return moduleList;
//   }

//   getModuleGroupList() : LabelValuePair[]{

//     let locale = this._translateService.currentLang == 'en' ? localeEn : localeTr;
//     const moduleGroupKeys = Object.keys(locale.locale.data.MODULES.GROUPVALUES);
//     let moduleGroupList : any[] = [];
//     moduleGroupKeys.forEach(moduleGroupKey => {
//       let moduleGroupLabel = locale.locale.data.MODULES.GROUPVALUES[moduleGroupKey].LABEL;
//       let moduleGroupModuleList = locale.locale.data.MODULES.GROUPVALUES[moduleGroupKey].MODULELIST;
//       moduleGroupList.push(
//         { label : moduleGroupLabel, value: moduleGroupModuleList }
//       )
//     });

//     return moduleGroupList;
//   }

//   getExtraPermission(role : any, item : Entity, currentUser : User){
//     //console.log("role:",role);
//     if(currentUser.userType == UserType.Admin) {
//       //console.log("adminim lan ben.");
//       return true;
//     }

//     switch (role.toString()) {
//       case "no" : 
//         return false;
//       case "own":
//         return item.assignedUserId == currentUser.id;
//       case "team":
//         return item.assignedUserId == currentUser.id || this.getTeamPermission(item,currentUser);
//       case "all":
//         return true;
//       default:
//         return false;
//     }
//   }

//   getTeamPermission(item: Entity, currentUser : User){
//     let itemTeams = item.teamsIds;
//     let userTeams = currentUser.teamsIds;

//     if((!itemTeams) || itemTeams.length == 0) return false;
//     if((!userTeams) || userTeams.length == 0) return false;

//     for(var itemTeam of itemTeams){
//       if(userTeams.some(t => t == itemTeam)) 
//         return true;
//     }

//     return false;
//   }

    setDateAddThreeHours(date: string): string {
        if (!date) {
            return undefined;
        }
        return this._datePipe.transform(moment(date).add(3, 'hours').format(), 'yyyy-MM-ddTHH:mm:ss');
    }

    setDateSubtractThreeHours(date: string): string {
        if (!date) {
            return undefined;
        }
        return this._datePipe.transform(moment(date).add(-3, 'hours').format(), 'yyyy-MM-ddTHH:mm:ss');
    }

    getMidnightDate(addTimePart: boolean = false) {
        return moment().format(`YYYY-MM-DD${addTimePart ? ' 03:00:00' : ''}`);
    }

    showPopupMessageWithTwoOptions(title: string, message: string, option1Text: string, option2Text: string, op1CallbackFunc, op2CallbackFunc) {
        Swal.fire({
            title: title,
            showConfirmButton: true,
            showCancelButton: true,
            text: message || '',
            // showClass: {
            //   popup: 'animate__animated animate__fadeIn'
            // },
            // hideClass: {
            //   popup: 'animate__animated animate__fadeOut'
            // },
            confirmButtonText: option1Text,
            cancelButtonText: option2Text,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-success ml-1'
            }
        }).then((result) => {
            if (result.dismiss && result.dismiss != Swal.DismissReason.cancel) {
                return;
            }

            result.isConfirmed ?
                !!op1CallbackFunc && op1CallbackFunc() :
                !!op2CallbackFunc && op2CallbackFunc();
        });
    }

    showPopupMessageForConfirmation(title: string, message: string, confirmButtonText: string, cancelButtonText: string, callback?) {
        Swal.fire({
            title: title,
            showConfirmButton: true,
            showCancelButton: true,
            text: message || '',
            // showClass: {
            //   popup: 'animate__animated animate__fadeIn'
            // },
            // hideClass: {
            //   popup: 'animate__animated animate__fadeOut'
            // },
            confirmButtonText: confirmButtonText || 'Evet',
            cancelButtonText: cancelButtonText || 'Hayır',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1'
            }
        }).then((result) => {
            if (result.dismiss && result.dismiss != Swal.DismissReason.cancel) {
                return;
            }

            if (result.isConfirmed) {
                !!callback && callback();
            }
        });
    }

    showPopupMessage(title: string, message: string, cancelButtonText: string) {
        Swal.fire({
            title: title,
            showConfirmButton: true,
            text: message || '',
            cancelButtonText: cancelButtonText || 'Tamam',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1'
            }
        }).then((result) => {
            if (result.dismiss && result.dismiss != Swal.DismissReason.cancel) {
                return;
            }
        });
    }
}
