
export class User {
  
  id: string;
  name: string;
  deleted: boolean;
  userName: string;
  type: string;
  authMethod?: any;
  apiKey?: any;
  salutationName?: any;
  firstName: string;
  lastName: string;
  isActive: boolean;
  title?: any;
  emailAddress: string;
  phoneNumber: string;
  gender: string;
  createdAt: string;
  modifiedAt: string;
  auth2FA?: any;
  lastAccess: string;
  middleName?: any;
  emailAddressIsOptedOut: boolean;
  phoneNumberIsOptedOut: boolean;
  emailAddressData: any[];
  phoneNumberData: any[];
  defaultTeamId?: any;
  defaultTeamName?: any;
  teamsIds: any[];
  teamsNames: any;
  teamsColumns: any;
  rolesIds: any[];
  rolesNames: any;
  portalsIds: any[];
  portalsNames: any;
  portalRolesIds: any[];
  portalRolesNames: any;
  contactId?: any;
  contactName: string;
  accountsIds: any[];
  accountsNames: any;
  avatarId?: any;
  avatarName?: any;
  createdById: string;
  createdByName: string;
  dashboardTemplateId?: any;
  dashboardTemplateName?: any;
  favorisIds: any[];
  favorisNames: any;
  authdata: string;
  password:string;
}

