import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  styles: [`
    :host {
      text-align: center;
      color: #1976D2;
    }
  `],
  selector: 'kovan-loading',
  templateUrl: './kovan-loading.component.html',
  styleUrls: ['./kovan-loading.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class KovanLoadingComponent implements OnInit {

  public message! : string;
  constructor() { }

  ngOnInit(): void {
  }

}
