import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { CapitalPipe } from './capital.pipe';
import { CorrectTimePipe } from './correctTime.pipe';
import { CurrencySignPipe } from './currencySign.pipe';
import { CustomCurrencyPipe } from './customCurrency.pipe';
import { HighlightTextPipe } from './highlightText.pipe';
import { MillisecondToDateFormatPipe } from './millisecondToDateFormat.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, CapitalPipe, CorrectTimePipe, CustomCurrencyPipe,CurrencySignPipe,HighlightTextPipe,MillisecondToDateFormatPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, CapitalPipe, CorrectTimePipe,CustomCurrencyPipe,CurrencySignPipe,HighlightTextPipe,MillisecondToDateFormatPipe]
})
export class CorePipesModule {}
