<ng-container *ngIf="!item.hidden">
  <!-- collapsible title -->
  <a
    [style.box-shadow]="item.isActive ? '3px 8px 15px burlywood' : null"
    [style.border-radius]="item.isActive ? '0.375rem' : null"
    [ngClass]="item.type == 'section' ? (item.isActive ? 'd-flex align-items-center dropdown-toggle nav-link active' : 'd-flex align-items-center dropdown-toggle nav-link') : 
              (item.isActive ? 'd-flex align-items-center dropdown-item dropdown-toggle active' : 'd-flex align-items-center dropdown-item dropdown-toggle')"
    *ngIf="!item.url"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->
  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span [translate]="item.translate">{{ item.title }}</span>
  </ng-template>

  <!-- sub-menu item/collapsible -->
  <ul class="dropdown-menu" [ngClass]="{ show: isShow }">
    <ng-container *ngFor="let item of item.children">
      <!-- item -->
      <li
        core-menu-horizontal-item
        *ngIf="
          item.type == 'item' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
        "
        [item]="item"
        [ngClass]="{ disabled: item.disabled === true}"
        [routerLink]="item.isProductCategory ? [] : (item.openInNewTab ? [] : [item.url])"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      ></li>
      <!-- collapsible -->
      <li
        core-menu-horizontal-collapsible
        *ngIf="item.type == 'collapsible'"
        [item]="item"
        class="dropdown dropdown-submenu"
      ></li>
    </ng-container>
  </ul>
</ng-container>
