import { enableProdMode, Inject, PLATFORM_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isPlatformBrowser } from '@angular/common';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    // PLATFORM_ID değerini almak için Angular Dependency Injection kullan
    const isBrowser = isPlatformBrowser(PLATFORM_ID);
    if (isBrowser) {
      require('hammerjs');
    }
    bootstrap()
      .catch(err => console.log(err)); 
  });
}
