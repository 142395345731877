import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { PhoneNumberMaskDirective } from './phone-number-mask/phone-number-mask.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective,PhoneNumberMaskDirective],
  exports: [RippleEffectDirective, FeatherIconDirective,PhoneNumberMaskDirective]
})
export class CoreDirectivesModule {}
