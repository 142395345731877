import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'millisecondToDateFormat'
})
export class MillisecondToDateFormatPipe implements PipeTransform {
    transform(value: number) {
        return this.getTimeParts(this.getTimePartsFromMillisecond(value))
    }

    getTimePartsFromMillisecond(diff : number){
        var seconds = Math.floor((diff / 1000) % 60);
        var minutes = Math.floor((diff / (1000 * 60)) % 60);
        var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        var days = Math.floor(diff / (1000 * 60 * 60 * 24) % 360);0
      
        return { g : days, s : hours , dk : minutes , sn : seconds };
    }

    getTimeParts(time : any) {
        
        let keys = Object.keys(time) || [];
        if(keys.length == 0 ) return "";
        let timeString = "";
        keys.forEach((key,index) => {
            if(time[key] == 0) return;
            timeString += `${time[key]} ${key} `
        });
      
        return timeString;
    }
}