import { Component, OnInit, Inject, HostListener, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {
    windowScrolled: boolean = false;
    topOffset: number = 150; // Scroll to top button display offset

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId)) {
            const scrollTop = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop;
            this.windowScrolled = scrollTop > this.topOffset;
        }
    }

    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            const smoothscroll = () => {
                const currentScroll = this.document.documentElement.scrollTop || this.document.body.scrollTop;

                if (currentScroll > 0) {
                    window.requestAnimationFrame(smoothscroll);
                    window.scrollTo(0, currentScroll - currentScroll / 8);
                }
            };
            smoothscroll();
        }
    }

    ngOnInit() {}
}
