import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'app/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EncDecrService } from './enc-decr.service';
import { Register } from '../models/auth/register';
import { environment } from '../../environments/environment';
import { ForgotPassword } from '../models/auth/forgotPassword';
import { ChangePassword } from '../models/auth/changePassword';
import { HOMEPAGE, LOGIN } from 'app/lib/routes/routes';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUser: Observable<User>;
    private responseUser: User = new User();
    public currentUserSubject: BehaviorSubject<User>;

    constructor(
        private _http: HttpClient,
        private _toastrService: ToastrService,
        private _router: Router,
        private _encDecrService: EncDecrService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(this.getCurrentUser);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get getCurrentUser(): User {
        const user = localStorage.getItem('currentUser');
        return user ? JSON.parse(this._encDecrService.get(user)) : null;
    }

    public setCurrentUser(user: User) {
        if (user) {
            localStorage.setItem('currentUser', this._encDecrService.set(JSON.stringify(user)));
            this.currentUserSubject.next(user);
        } else {
            console.error('User is undefined or null');
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    get isAdmin() {
        return this.getCurrentUser && this.getCurrentUser.type === 'admin';
    }

    get isClient() {
        return this.currentUserValue && this.currentUserValue.type === 'regular';
    }

    login(username: string, password: string) {
        return this.getCrmUser(username, password);
    }

    updateUserAndGotoHomePage(user: any, userName: string, password: any) {
        const authData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        return this._http.put(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${user.id}`, {
            dorulamaKoduOnayland: true
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${authData}`
            })
        });
    }

    setCurrentUserAndLogin(user: any, username: string, password: string) {
        this.responseUser = this.convertResponseToUser(user);
        this.responseUser.authdata = window.btoa(username + ':' + password);
        this.responseUser.password = password;

        this._toastrService.success(
            'Giriş başarılı',
            '👋 Hoşgeldiniz, ' + this.responseUser.firstName + '!',
            { toastClass: 'toast ngx-toastr', closeButton: true }
        );

        this.setCurrentUser(this.responseUser);
        this._router.navigate([HOMEPAGE]);
    }

    setCurrentUserAndLoginnonuser(user: any, username: string, password: string) {
        this.responseUser = this.convertResponseToUser(user);
        this.responseUser.authdata = window.btoa(username + ':' + password);
        this.responseUser.password = password;

        this.setCurrentUser(this.responseUser);
        this._router.navigate([HOMEPAGE]);
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this._router.navigate([LOGIN]);
    }

    register(register: Register) {
        const authData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}`,
            register,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${authData}`
                })
            });
    }

    getCrmUser(username: string, password: string) {
        return this._http.get<any>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.appUser}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
                })
            });
    }

    forgotPasswordSendEmail(forgotPassword: ForgotPassword) {
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${environment.forgotPassword}`,
            forgotPassword, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    changePassword(changePassword: ChangePassword) {
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${environment.changePassword}`,
            changePassword, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            });
    }

    setCurrentDogrulamaKodu(dogrulamaKoduBody: any, id: string) {
        const authData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        return this._http.put<User>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${id}`,
            dogrulamaKoduBody, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${authData}`
                })
            });
    }

    private convertResponseToUser(responseUserObj: User): User {
        return responseUserObj;
    }
}
