import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(number: number , currency : string): string {
    
    if(!currency){
        currency = "TRY"
    }
      
    let result = "";
    var formatter = new Intl.NumberFormat("tr-TR", {
        style: 'currency',
        currency: currency,
      });
    result = formatter.format(number);
    return result;
  }

  getCurrencyFormatData(currency : string){
    switch (currency) {
        case "TRY":
            return "tr-TR"
        case "EUR":
            return "en-EN"
        case "GBP": 
            return "en-EN"
        case "USD":
            return "en-US"
        default:
            return "tr-TR"
      }
  }
}