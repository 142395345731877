import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'core-theme-customizer',
  templateUrl: './theme-customizer.component.html',
  styleUrls: ['./theme-customizer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoreThemeCustomizerComponent implements OnInit, OnDestroy {
  navbarColorValue: string;
  coreConfig: any;
  form: FormGroup;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    @Inject(PLATFORM_ID) private platformId: Object // Inject PLATFORM_ID to determine environment
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      app: this._formBuilder.group({
        appName: new FormControl(),
        appTitle: new FormControl(),
        appLogoImage: new FormControl()
      }),
      layout: this._formBuilder.group({
        skin: new FormControl(),
        type: new FormControl(),
        menu: this._formBuilder.group({
          hidden: new FormControl(),
          collapsed: new FormControl()
        }),
        navbar: this._formBuilder.group({
          hidden: new FormControl(),
          type: new FormControl(),
          background: new FormControl(),
          customBackgroundColor: new FormControl(),
          backgroundColor: new FormControl()
        }),
        footer: this._formBuilder.group({
          hidden: new FormControl(),
          type: new FormControl(),
          background: new FormControl(),
          customBackgroundColor: new FormControl(),
          backgroundColor: new FormControl()
        }),
        enableLocalStorage: new FormControl(),
        customizer: new FormControl(),
        scrollTop: new FormControl(),
        buyNow: new FormControl()
      })
    });

    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.form.setValue(config, { emitEvent: false });
    });

    this.form
      .get('layout.type')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this._resetFormValues(value);
      });

    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this._coreConfigService.config = config;
    });

    this.navbarColor(this.form.get('layout.navbar.backgroundColor').value);

    // Check if we are in the browser before using localStorage
    if (isPlatformBrowser(this.platformId)) {
      const storedConfig = localStorage.getItem('appConfig');
      if (storedConfig) {
        this.form.setValue(JSON.parse(storedConfig));
      }

      this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
        localStorage.setItem('appConfig', JSON.stringify(config));
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _resetFormValues(value): void {
    switch (value) {
      case 'vertical': {
        this.form.patchValue({
          layout: {
            menu: { hidden: false, collapsed: false },
            navbar: { hidden: false, type: 'floating-nav', background: 'navbar-light', customBackgroundColor: true, backgroundColor: '' },
            footer: { hidden: false, type: 'footer-static', background: 'footer-light', customBackgroundColor: false, backgroundColor: 'bg-primary' }
          }
        });
        break;
      }
      case 'horizontal': {
        this.form.patchValue({
          layout: {
            menu: { hidden: false, collapsed: false },
            navbar: { hidden: false, type: 'floating-nav', background: 'navbar-light', customBackgroundColor: true, backgroundColor: '' },
            footer: { hidden: false, type: 'footer-static', background: 'footer-light', customBackgroundColor: false, backgroundColor: 'bg-primary' }
          }
        });
        break;
      }
    }

    this.navbarColor(this.form.get('layout.navbar.backgroundColor').value);
  }

  navbarColor(value): void {
    this.navbarColorValue = value;
    this.form.patchValue({
      layout: { navbar: { customBackgroundColor: true, backgroundColor: this.navbarColorValue } }
    });
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
}