import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {NOT_AUTHORIZED} from '../routes/routes';
import { UtilsService } from 'app/services/utilsService.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private _router: Router, 
      private _authenticationService: AuthenticationService,
      private _utilsService : UtilsService) {}

  
  @BlockUI() protected _blockUi: NgBlockUI;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        
        //Bunu yapmamın sebebi. Tüm observablelarda error kısmına girildiğinde 
        //tekrar tekrar bunun yazmamak için;
        this._utilsService.stopLoading();
        
        if(err?.name == "TimeoutError"){
          this._utilsService.showError("İsteğiniz zaman aşımına uğradı.");
        }

        if ([401].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          //this._router.navigate([NOT_AUTHORIZED]);
          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }
        // throwError
        return throwError(err);
      })
    );
  }
}
