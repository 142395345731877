<ng-template let-profileModal #profileModal>
    <div class="modal-header">
        <h5 class="modal-title">Profil</h5>
        <button type="button" class="close" (click)="closeProfileModal(profileModal)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="content-wrapper">
            <div class="content-body">
                <section class="users-list-wrapper">
                    <div class="card">
                        <div class="column col-md-12 p-3">
                            <h5>Kullanıcı adı:</h5>
                            <label for="">{{currentUser.name}}</label>
                        </div>
                        <div class="column col-md-12 p-3">
                            <h5>Mail Adresi</h5>
                            <label for="">{{currentUser.emailAddress}}</label>
                        </div>
                        <div class="column col-md-12 p-3">
                            <h5>Telefon</h5>
                            <label for="">{{currentUser.phoneNumber}}</label>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<nav id="nav" class="navbar navbar-expand-lg bg-white w-100 position-fixed" style="height: 66px; z-index: 9999;">
    <div class="container for-mobil d-block">
        <div class="row">
            <!-- Mobile -->
            <div class="col-2 text-left d-lg-none d-block align-self-center">
                <div (click)="menuOpenOrClose()" *ngIf="!mobileMenu">
                    <i [data-feather]="'align-justify'" class="font-large-1"></i>
                </div>
            </div>
            <div class="col-7 text-center d-lg-none d-block align-self-center">
                <a class="navbar-brand m-0" [routerLink]="['/']">
                    <div class="row">
                        <div class="col-12">
                            <span class="brand-logo">
                                <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="250px"/>
                            </span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-3 text-right d-lg-none d-block align-self-center">
                <ul class="navbar-nav pt-1 mb-lg-0" style="flex-direction: row-reverse;">
                    <li class="pl-2f">
                        <div class="mb-2" [routerLink]="'login'" *ngIf="girisyapan()">
                            <i [data-feather]="'user'" class="font-large-1"></i>
                        </div>
                        <div class="mb-2" [routerLink]="'favourite-varliklar'" *ngIf="!girisyapan()">
                            <i [data-feather]="'heart'" class="font-large-1"></i>
                        </div>
                    </li>
                    <li class="pl-2f">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"
                             fill="none"
                             stroke="currentColor"
                             stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-headphones"
                             (click)="displaychatbox()">
                            <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                            <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                        </svg>
                    </li>
                </ul>
            </div>
            <!-- Desktop -->
            <div class="col-2 d-lg-block d-none align-self-center">
                <a class="navbar-brand" [routerLink]="['/']">
                    <div class="row">
                        <div class="col-12">
                            <span class="brand-logo">
                                <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="250px"/>
                            </span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-7 d-lg-block d-none align-self-center">
                <div id="navbarSupportedContent" class="collapse navbar-collapse" style="justify-content: center;">
                    <ul class="navbar-nav mb-2 mb-lg-0 " *ngIf="!_searchEnable">
                        <li ngbDropdown class="nav-item dropdown-user">
                            <a class="nav-link dropdown-user-link" id="dropdown-user1"
                               id="navbarUserDropdown1" aria-haspopup="true"
                               style="justify-content: center;text-align: center">
                                İLÇELERİMİZ
                            </a>
                            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown1" class="navbardropdown full-menu">
                                <div class="row">
                                    <div class="col-3 p-5 bg-blue">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="haritaSvg"
                                             version="1.0"
                                             viewBox="0 0 579.000000 600.000000" preserveAspectRatio="xMidYMid meet">
                                            <g style="height: 100%;"
                                               transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
                                               fill="#FFFFFF" stroke="none">
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'AFŞİN')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'AFŞİN')"
                                                      (click)="filterDataByIlce('AFŞİN',$event,38.2462643,36.920267)"
                                                      d="M3220 5828 c-36 -5 -104 -16 -152 -24 -47 -8 -128 -17 -180 -21 -97 -6 -117 -12 -313 -88 -66 -26 -142 -55 -168 -65 -26 -9 -58 -26 -70 -37 -12 -11 -37 -30 -54 -42 -37 -25 -43 -59 -22 -122 16 -47 37 -69 87 -91 74 -33 69 -71 -20 -164 -75 -79 -92 -80 -160 -19 -72 64 -93 67 -137 19 l-35 -38 90 -42 c71 -33 93 -49 102 -71 19 -48 29 -164 25 -283 -5 -108 -4 -117 19 -149 24 -34 41 -46 119 -81 105 -47 167 -132 189 -259 14 -80 56 -189 84 -218 14 -16 45 -27 99 -37 100 -19 130 -17 370 34 34 7 39 6 50 -18 17 -35 11 -61 -20 -91 -23 -22 -25 -29 -19 -80 4 -31 10 -59 14 -63 13 -13 122 85 167 151 45 66 45 67 55 188 6 68 10 196 8 285 -2 147 0 176 26 298 25 118 28 148 23 241 -4 58 -11 138 -17 176 -13 85 1 305 24 373 19 57 46 224 46 289 0 48 0 48 -37 55 -48 7 -110 6 -193 -6z"
                                                      style="fill: #cedbec; outline: none; border: none;"
                                                      role="button" tabindex="0"
                                                      id="afsin"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'ELBİSTAN')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'ELBİSTAN')"
                                                      (click)="filterDataByIlce('ELBİSTAN',$event,38.2075617,37.2016077)"
                                                      d="M3470 5773 c-1 -60 -29 -232 -53 -318 -20 -70 -30 -267 -17 -340 5 -33 13 -112 16 -175 6 -103 3 -128 -22 -240 -25 -114 -27 -141 -26 -310 1 -102 -2 -223 -7 -270 -4 -47 -6 -87 -5 -89 2 -2 93 21 201 52 178 49 205 55 268 50 67 -5 72 -7 117 -51 l47 -45 104 -11 104 -11 56 -51 c52 -48 60 -52 123 -59 36 -4 159 -6 273 -4 140 2 213 -1 227 -8 42 -24 197 -44 354 -47 l160 -3 59 62 c54 58 83 102 150 230 24 47 26 59 26 185 0 128 -1 137 -25 169 -14 19 -36 57 -50 85 -20 39 -38 58 -77 81 -34 20 -58 44 -73 73 -26 48 -90 92 -134 92 -38 0 -62 15 -128 76 -43 41 -64 54 -88 54 -56 0 -143 54 -214 133 -55 60 -76 77 -118 91 -27 9 -59 16 -70 16 -11 0 -34 11 -53 25 -46 36 -191 84 -283 95 -195 22 -281 66 -284 144 -2 46 39 106 102 154 110 82 130 102 130 131 0 16 -9 39 -19 52 -19 23 -23 24 -168 23 -81 0 -206 -4 -277 -9 -120 -7 -165 -4 -298 21 -28 6 -28 5 -28 -53z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="elbistan"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'GÖKSUN')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'GÖKSUN')"
                                                      (click)="filterDataByIlce('GÖKSUN',$event,38.0214051,36.4984414)"
                                                      d="M1950 5068 c-19 -23 -45 -65 -56 -94 -12 -29 -42 -84 -67 -123 -26 -38 -53 -85 -63 -102 -15 -29 -49 -63 -96 -97 -10 -7 -50 -22 -89 -33 -72 -20 -202 -80 -214 -99 -3 -5 -31 -10 -62 -10 l-56 0 7 -73 c6 -72 6 -76 -74 -313 l-81 -239 -115 -172 c-62 -95 -114 -175 -114 -176 0 -2 22 -2 49 1 38 3 66 -2 121 -24 39 -15 73 -31 76 -36 3 -5 -40 -65 -95 -135 l-101 -126 0 -86 c0 -78 3 -93 35 -160 31 -64 38 -72 58 -67 101 25 219 38 292 31 44 -4 95 -7 112 -6 18 0 47 -8 65 -19 43 -26 56 -25 64 5 3 14 26 60 49 101 24 41 57 110 75 152 26 63 41 85 82 117 27 22 70 57 96 78 75 61 136 88 213 94 43 4 79 1 97 -6 16 -7 62 -49 103 -93 l73 -81 74 7 c83 7 122 25 171 82 40 45 56 78 71 144 9 43 9 61 -1 85 -26 65 3 159 53 169 63 12 141 -6 242 -56 80 -40 105 -49 132 -44 41 8 44 23 22 106 -24 92 -22 123 7 158 14 17 25 42 25 57 0 27 0 27 -52 20 -29 -4 -69 -11 -88 -17 -19 -5 -69 -13 -110 -18 -94 -11 -233 11 -267 42 -23 22 -78 151 -88 208 -28 161 -64 212 -205 283 -122 62 -125 67 -126 245 -1 137 -7 221 -21 266 -5 16 -157 97 -179 95 -5 0 -25 -19 -44 -41z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="goksun"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'EKİNÖZÜ')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'EKİNÖZÜ')"
                                                      (click)="filterDataByIlce('EKİNÖZÜ',$event,38.0600276,37.1887265)"
                                                      d="M3552 4061 l-193 -56 -53 -78 c-33 -50 -76 -97 -118 -132 -37 -29 -65 -55 -62 -58 2 -2 6 -29 7 -60 2 -41 11 -68 33 -104 25 -41 39 -52 90 -72 81 -31 98 -59 89 -144 -6 -52 -12 -68 -41 -97 -18 -20 -45 -65 -60 -100 -22 -55 -26 -76 -22 -135 3 -39 11 -81 18 -94 l13 -25 39 42 c46 49 77 67 137 76 86 13 190 63 253 121 32 30 71 58 86 62 31 7 116 57 152 88 14 12 42 32 62 44 25 15 46 40 63 76 l27 54 -18 58 c-24 83 -23 177 3 234 23 52 72 87 174 123 l58 21 -52 46 c-52 45 -53 46 -156 57 -104 11 -104 11 -150 55 -44 42 -49 45 -116 49 -63 5 -89 0 -263 -51z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="ekinozu"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'NURHAK')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'NURHAK')"
                                                      (click)="filterDataByIlce('NURHAK',$event,37.9654009,37.448205)"
                                                      d="M4270 3874 c-56 -12 -152 -59 -176 -85 -8 -9 -22 -43 -30 -74 -16 -60 -13 -102 12 -190 13 -45 13 -55 -3 -97 -9 -26 -28 -59 -42 -74 -44 -44 -162 -123 -236 -159 -38 -18 -88 -51 -109 -74 -43 -44 -169 -104 -246 -116 -61 -10 -114 -38 -140 -75 -12 -17 -21 -33 -19 -34 16 -14 263 -76 303 -76 27 0 63 -4 80 -9 209 -63 255 -70 376 -55 58 7 146 13 196 14 51 0 129 7 175 16 95 18 195 14 254 -10 20 -9 60 -41 88 -71 27 -30 56 -55 62 -55 7 0 44 24 81 53 38 29 94 68 124 86 52 32 160 118 188 151 7 8 31 53 53 100 31 65 42 101 46 155 l6 71 -38 28 c-51 40 -102 96 -113 126 -33 90 -29 163 11 241 16 31 42 56 87 87 120 79 121 77 -51 79 -144 2 -288 21 -332 45 -27 14 -547 16 -607 2z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="nurhak"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'ONİKİŞUBAT')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'ONİKİŞUBAT')"
                                                      (click)="filterDataByIlce('ONİKİŞUBAT',$event,37.5879578,36.8831883)"
                                                      d="M2691 3731 c-29 -29 -36 -65 -22 -125 28 -118 -46 -263 -166 -325 -12 -6 -56 -14 -98 -18 l-77 -6 -88 91 -87 92 -57 0 c-70 0 -151 -28 -208 -71 -152 -118 -164 -131 -204 -219 -20 -47 -54 -114 -74 -150 -64 -111 -64 -110 22 -260 56 -100 102 -139 229 -196 46 -21 86 -39 87 -40 13 -9 -30 -89 -75 -138 -47 -53 -53 -64 -53 -104 0 -38 5 -49 36 -78 19 -19 39 -34 43 -34 4 0 12 -15 19 -33 31 -85 1 -159 -100 -248 -52 -47 -79 -80 -99 -123 -40 -84 -44 -128 -24 -264 l17 -119 -27 -52 c-15 -30 -39 -60 -55 -69 -40 -23 -50 -51 -50 -138 0 -48 -6 -86 -15 -105 -19 -35 -24 -33 115 -47 58 -6 142 -22 188 -35 100 -29 144 -66 163 -135 6 -26 14 -51 17 -56 9 -17 20 19 27 88 4 39 17 98 30 131 12 33 28 84 35 114 13 57 22 64 150 134 67 36 79 66 51 125 l-21 43 22 32 c12 18 22 33 22 33 1 1 16 5 33 9 30 6 35 3 54 -29 24 -42 59 -47 99 -16 28 22 58 25 113 9 22 -6 41 -9 43 -7 2 3 -2 18 -8 35 -11 29 -8 36 32 100 25 37 73 100 107 139 76 86 94 125 104 229 4 44 11 107 15 140 l6 61 -56 43 c-32 25 -62 53 -67 64 -17 30 -2 66 52 125 53 59 62 94 35 149 -26 53 -38 118 -43 229 -5 121 4 172 40 225 21 32 27 34 92 39 48 3 82 12 108 28 21 12 47 22 58 22 16 0 19 7 19 44 0 59 44 163 85 201 26 24 34 41 39 86 10 80 -3 103 -78 132 -63 24 -101 64 -123 132 -9 27 -14 30 -46 27 -27 -2 -64 10 -141 47 -128 60 -212 75 -245 42z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="onikisubat"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'DULKADİROĞLU')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'DULKADİROĞLU')"
                                                      (click)="filterDataByIlce('DULKADİROĞLU',$event,37.5789825,36.9695747)"
                                                      d="M3130 2986 c-26 -14 -75 -28 -115 -33 l-70 -8 -23 -47 c-32 -64 -42 -223 -20 -295 9 -26 19 -64 22 -84 4 -21 13 -42 21 -49 8 -7 15 -29 15 -50 0 -31 -9 -48 -55 -99 -66 -74 -71 -104 -22 -141 17 -14 47 -39 66 -57 30 -30 33 -37 27 -76 -3 -23 -10 -83 -16 -134 -13 -116 -36 -171 -108 -251 -69 -77 -142 -189 -142 -217 1 -34 23 -52 76 -59 45 -7 58 -14 106 -64 31 -31 74 -82 98 -112 23 -30 61 -67 84 -82 53 -35 76 -58 76 -76 0 -7 27 -47 60 -87 63 -76 96 -94 143 -76 37 15 54 43 61 105 6 52 4 62 -15 83 -13 12 -28 23 -34 23 -7 0 -27 30 -45 66 l-32 66 18 39 c28 63 86 106 250 185 124 60 166 86 218 134 93 87 161 197 181 290 l16 74 -47 166 c-27 99 -59 190 -80 227 -30 55 -40 63 -90 84 -60 25 -87 55 -139 158 -20 40 -29 75 -32 133 -5 71 -8 78 -27 78 -12 -1 -87 18 -167 42 -160 46 -168 52 -184 136 -8 38 -16 39 -75 8z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="dulkadiroglu"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'ANDIRIN')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'ANDIRIN')"
                                                      (click)="filterDataByIlce('ANDIRIN',$event,37.5746445,36.3531017)"
                                                      d="M1185 2916 c-5 -2 -42 -9 -80 -15 -105 -18 -105 -18 -57 -96 23 -36 42 -70 42 -74 0 -4 -62 -24 -137 -45 l-137 -37 -131 -97 c-71 -54 -195 -143 -273 -197 l-144 -99 -49 -98 -50 -99 38 20 c21 11 58 29 83 40 25 12 79 54 122 95 75 73 148 118 191 118 12 1 60 -3 107 -7 84 -7 86 -6 153 27 37 19 69 32 71 29 2 -3 -6 -26 -17 -51 -23 -49 -84 -112 -214 -222 -118 -98 -111 -132 25 -131 131 1 150 -24 156 -201 2 -56 12 -119 25 -163 20 -67 20 -75 6 -115 -8 -24 -15 -56 -15 -71 0 -15 -7 -39 -17 -53 -14 -22 -15 -34 -3 -109 12 -79 12 -84 -9 -118 -28 -46 -27 -76 8 -124 39 -53 61 -98 61 -121 0 -18 18 -32 41 -32 5 0 30 19 54 41 30 28 54 42 73 42 44 0 104 -29 118 -56 12 -23 19 -25 68 -24 45 1 56 5 64 23 12 26 102 74 139 74 43 0 63 44 63 138 0 83 19 130 59 152 12 6 33 34 47 61 l25 51 -15 100 c-20 121 -20 160 -2 219 25 82 53 125 126 189 39 35 80 80 91 101 35 69 22 134 -35 171 -43 28 -56 55 -56 113 0 39 7 52 55 109 30 35 59 76 64 89 9 23 6 27 -41 51 -27 14 -56 26 -63 26 -7 0 -43 22 -80 49 -57 42 -76 64 -121 140 -29 50 -55 99 -59 110 -6 21 -95 64 -140 67 -119 9 -222 13 -230 10z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="andirin"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'ÇAĞLAYANCERİT')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'ÇAĞLAYANCERİT')"
                                                      (click)="filterDataByIlce('ÇAĞLAYANCERİT',$event,37.7445878,37.2958773)"
                                                      d="M3603 2722 c4 -69 10 -89 47 -153 50 -89 62 -101 122 -123 75 -28 115 -105 177 -342 10 -37 17 -44 69 -67 90 -41 160 -43 265 -10 79 26 94 35 153 93 64 63 104 125 118 184 10 39 61 110 93 127 14 7 36 36 50 64 14 27 42 68 64 90 l39 40 -63 65 c-53 56 -69 67 -115 78 -70 16 -120 15 -215 -3 -43 -8 -120 -15 -170 -15 -51 -1 -139 -7 -197 -14 -114 -14 -130 -12 -323 38 -53 15 -102 26 -109 26 -7 0 -9 -24 -5 -78z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="caglayancerit"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'PAZARCIK')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'PAZARCIK')"
                                                      (click)="filterDataByIlce('PAZARCIK',$event,37.4857521,37.2890447)"
                                                      d="M4625 2384 c-19 -20 -38 -48 -43 -63 -5 -14 -18 -48 -27 -76 -13 -38 -37 -69 -99 -130 -80 -80 -82 -81 -178 -109 -110 -32 -178 -31 -254 7 -24 11 -46 19 -49 16 -2 -3 0 -21 5 -40 8 -25 6 -55 -6 -114 -12 -66 -24 -93 -66 -155 -94 -138 -176 -208 -311 -266 -157 -67 -241 -125 -274 -187 -14 -27 -13 -33 14 -88 15 -32 33 -59 39 -59 5 0 22 -13 37 -28 26 -27 27 -32 21 -100 -10 -101 -44 -131 -145 -124 -18 2 -43 24 -92 83 -37 44 -67 88 -67 97 -1 9 -15 -10 -32 -43 -16 -33 -40 -71 -53 -85 -37 -40 -65 -93 -65 -121 0 -23 5 -27 40 -33 23 -3 54 -18 71 -34 29 -26 32 -35 41 -117 5 -50 12 -112 16 -140 l7 -50 72 -35 c90 -43 107 -60 136 -135 19 -49 29 -63 60 -78 20 -10 45 -16 56 -12 38 12 111 170 111 238 0 33 48 84 202 213 42 36 93 87 113 114 54 74 89 95 155 94 40 0 70 -8 107 -27 45 -23 64 -27 140 -27 49 0 104 5 123 10 19 6 66 15 104 20 52 8 98 25 175 64 101 51 111 54 206 61 88 6 103 10 127 32 22 21 28 37 33 92 9 103 12 108 64 143 71 48 117 108 131 170 6 29 13 62 16 73 4 17 -11 33 -75 83 -97 75 -106 98 -96 238 7 92 7 92 -19 109 -23 15 -30 16 -57 4 -18 -7 -44 -23 -59 -36 -51 -42 -78 -52 -118 -41 -51 13 -225 115 -241 140 -7 11 -15 44 -18 72 l-5 51 66 65 c36 36 66 72 66 80 0 9 -13 30 -30 48 -35 39 -43 65 -38 127 2 25 1 45 -1 45 -2 0 -18 -16 -36 -36z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="pazarcik"
                                                />
                                                <path fill="#FFFFFF" (mouseover)="mouseOver($event, 'TÜRKOĞLU')"
                                                      (mouseout)="mouseOut()"
                                                      (mousemove)="mouseOver($event, 'TÜRKOĞLU')"
                                                      (click)="filterDataByIlce('TÜRKOĞLU',$event,37.3841023,36.8572468)"
                                                      d="M2389 1413 c-9 -2 -22 -15 -29 -28 -11 -20 -10 -31 4 -68 29 -76 4 -112 -122 -176 -65 -33 -76 -45 -86 -102 -4 -20 -17 -63 -30 -95 -13 -33 -27 -90 -31 -129 -4 -38 -13 -82 -20 -96 -13 -25 -11 -29 70 -112 95 -99 140 -171 156 -250 l11 -55 37 13 c20 7 70 23 111 35 185 54 217 70 289 146 l68 71 7 74 c11 114 25 159 53 172 19 8 30 7 54 -7 29 -17 29 -17 29 7 0 14 16 47 35 73 60 84 87 126 101 161 14 31 13 33 -13 48 -40 21 -62 42 -120 115 -28 36 -71 84 -95 108 -40 38 -51 43 -101 47 -30 2 -82 9 -115 16 -55 12 -62 11 -94 -9 -56 -35 -99 -28 -124 19 -8 16 -18 28 -22 28 -4 -1 -14 -3 -23 -6z"
                                                      style="fill: #cedbec; outline: none; border: none;" role="button"
                                                      tabindex="0"
                                                      id="turkoglu"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="col-9">
                                        <div class="row">
                                            <div class="col-12 p-5">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <label class="labelS"
                                                               (click)="enterfunc('Afşin','afsin'); gomapilce('ilce',38.2462643,36.920267,'afsin',1);"
                                                               (mouseover)="enterfunc('Afşin','afsin')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Afşin</a>
                                                        </label>
                                                        <label class="labelS"
                                                               (click)="enterfunc('Göksun','goksun'); gomapilce('ilce',38.0214051,36.4984414,'goksun',1);"
                                                               (mouseover)="enterfunc('Göksun','goksun')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Göksun</a>
                                                        </label>

                                                    </div>
                                                    <div class="col-2">
                                                        <label class="labelS"
                                                               (click)="enterfunc('Andırın','andirin'); gomapilce('ilce',37.5746445,36.3531017,'andirin',1);"
                                                               (mouseover)="enterfunc('Andırın','andirin')">
                                                            <a style="color:#fff">Andırın</a>
                                                        </label>
                                                        <label class="labelS"
                                                               (click)="enterfunc('Nurhak','nurhak') ; gomapilce('ilce',37.9654009,37.448205,'nurhak',1);"
                                                               (mouseover)="enterfunc('Nurhak','nurhak')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Nurhak</a>
                                                        </label>

                                                    </div>
                                                    <div class="col-2">
                                                        <label class="labelS"
                                                               (click)="enterfunc('Çağlayancerit','caglayancerit') ; gomapilce('ilce',37.7445878,37.2958773,'caglayancerit',1);"
                                                               (mouseover)="enterfunc('Çağlayancerit','caglayancerit')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Çağlayancerit</a>
                                                        </label>


                                                        <label class="labelS"
                                                               (click)="enterfunc('Onikişubat','onikisubat'); gomapilce('ilce',37.5879578,37.8831883,'onikisubat',1);"
                                                               (mouseover)="enterfunc('Onikişubat','onikisubat')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Onikişubat</a>
                                                        </label>


                                                    </div>
                                                    <div class="col-2">
                                                        <label class="labelS"
                                                               (click)="enterfunc('Dulkadiroğlu','dulkadiroglu') ; gomapilce('ilce',37.5789825,36.9695747,'dulkadiroglu',1);"
                                                               (mouseover)="enterfunc('Dulkadiroğlu','dulkadiroglu')"
                                                               (mouseout)="enterfunc('','')" >
                                                            <a style="color:#fff">Dulkadiroğlu</a>
                                                        </label>

                                                        <label class="labelS"
                                                               (click)="enterfunc('Pazarcık','pazarcik'); gomapilce('ilce',37.4857521,37.2890447,'pazarcik',1);"
                                                               (mouseover)="enterfunc('Pazarcık','pazarcik')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Pazarcık</a>
                                                        </label>

                                                    </div>
                                                    <div class="col-2">

                                                        <label class="labelS"
                                                               (click)="enterfunc('Ekinözü','ekinozu') ; gomapilce('ilce',38.0600276,37.1887265,'ekinozu',1);"
                                                               (mouseover)="enterfunc('Ekinözü','ekinozu')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Ekinözü</a>
                                                        </label>

                                                        <label class="labelS"
                                                               (click)="enterfunc('Türkoğlu','turkoglu'); gomapilce('ilce',37.3841023,36.8572468,'turkoglu',1);"
                                                               (mouseover)="enterfunc('Türkoğlu','turkoglu')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Türkoğlu</a>
                                                        </label>

                                                    </div>
                                                    <div class="col-2">
                                                        <label class="labelS"
                                                               (click)="enterfunc('Elbistan','elbistan') ; gomapilce('ilce',38.2075617,37.2016077,'elbistan',1);"
                                                               (mouseover)="enterfunc('Elbistan','elbistan')"
                                                               (mouseout)="enterfunc('','')">
                                                            <a style="color:#fff">Elbistan</a>
                                                        </label>
                                                        <label class="labelS"
                                                               (click)="gomapilce('ilce',37.874861111111,36.908388888889,'tumu',1);">
                                                            <a style="color:#fff">Tümünü Gör</a>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12 bg-white p-5 ">
                                                <div class="row">
                                                    <div class="col-8 align-self-center">
                                                        <div class="">
                                                            <!--<div style="width: 100%; float: left;">
                                                                <label class="secilibaslik"> {{secilietiket.name}} </label>
                                                            </div>-->
                                                            <div class="etiketaciklama">
                                                                {{secilietiket.description}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4"
                                                         style="heigt: 250px; max-height: 250px; overflow: hidden">
                                                        <img class="imgresim" alt="avatar" class="w-100"
                                                             src="{{envire.crmimages}}{{secilietiket.resimId}}"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li ngbDropdown class="nav-item dropdown-user">
                            <a class="nav-link dropdown-user-link" id="dropdown-user2"
                               id="navbarUserDropdown2" aria-haspopup="true" aria-expanded="false"
                               style="justify-content: center; text-align: center">
                                <ng-container *ngIf="this.currentUser">
                                    GEZİ ROTALARIMIZ
                                </ng-container>
                            </a>

                            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown2" class="navbardropdown full-menu">
                                <div class="row">
                                    <div class="col-12" *ngIf="_sureliRota.length > 0">
                                        <div class="row px-5 py-3">
                                            <div class="col-3" *ngFor="let rota of _sureliRota; let i=index;"
                                                 (mouseenter)="rotamouseover(rota)">
                                                <div class="row mx-0 nav-route">
                                                    <div class="col-5" *ngIf="rota.pictureId; else noImg">
                                                        <a href="/rota-detay?id={{rota.id}}">
                                                            <img width="100%" height="70px"
                                                                 src="{{envire.crmimages}}{{rota.pictureId}}"
                                                                 style="border-radius: 10px"
                                                            />
                                                        </a>
                                                    </div>
                                                    <ng-template #noImg>
                                                        <div class="col-5">
                                                            <a href="/rota-detay?id={{rota.id}}">
                                                                <img width="100%" height="70px"
                                                                     src="assets/images/resim_yok.png"
                                                                     loading="lazy" alt="..."
                                                                     style="object-fit: contain; border-radius: 10px">
                                                            </a>
                                                        </div>
                                                    </ng-template>
                                                    <div class="col-7 align-self-center">
                                                        <label (click)="goRotaDetail(rota)" class="navrotalabel">
                                                            {{rota.name}}</label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                             viewBox="0 0 24 24"
                                                             fill="none"
                                                             stroke="currentColor" stroke-width="2"
                                                             stroke-linecap="round"
                                                             stroke-linejoin="round"
                                                             class="feather feather-map navsvgrotas"
                                                             style="float: right;"
                                                             (click)="goMapRota(rota)">
                                                            <polygon
                                                                    points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                                            <line x1="8" y1="2" x2="8" y2="18"></line>
                                                            <line x1="16" y1="6" x2="16" y2="22"></line>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 bg-white">
                                        <div class="row px-5 py-3">
                                            <div class="col-4"
                                                 style="height: 200px; max-height: 200px; overflow-y: scroll">
                                                <div class="navrotadetayname"
                                                     *ngFor="let rotadetay of _sureliRotaDetay; let i=index;"
                                                     (mouseenter)="varlikmouseover(rotadetay)">
                                                    <label (click)="routevarlikdetay(rotadetay)" class="rotadetayisim">
                                                        {{rotadetay.sira}}. {{rotadetay.name}}</label>
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="row" *ngIf="_secilidetay">
                                                    <div class="col-4 align-self-center"
                                                         *ngIf="_secilidetay.resimId; else noImg">
                                                        <img class="navrotaimg"
                                                             src="{{envire.crmimages}}{{_secilidetay.resimId}}"/>
                                                    </div>
                                                    <ng-template #noImg>
                                                        <div class="col-4">
                                                            <img class="navrotaimg" src="assets/images/resim_yok.png"
                                                                 loading="lazy" alt="..." style="object-fit: contain;">
                                                        </div>
                                                    </ng-template>
                                                    <div class="col-8 align-self-center">
                                                        <div class="navdetaydesc">
                                                            <p class="ucnokta">{{_secilidetay.vgenelbilgisi}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li ngbDropdown class="nav-item dropdown-user">
                            <a class="nav-link dropdown-user-link" id="dropdown-user3"
                               id="navbarUserDropdown3" aria-haspopup="true" aria-expanded="false"
                               style="justify-content: center; text-align: center">
                                <ng-container *ngIf="this.currentUser">DENEYİMLERİMİZ
                                </ng-container>
                            </a>
                            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown3" class="navbardropdown full-menu"
                                 style="max-height: fit-content;">
                                <div class="row p-5">
                                    <div class="col-2 m-auto" style="margin-bottom: 15px !important;"
                                         *ngFor="let deneyim of deneyimler; let i=index;"
                                         (click)="getVarlikOfEtikets(deneyim)">
                                        <div class="row experience">
                                            <div class="col-12" style="z-index: 999;position: absolute;">
                                                <div class="dv1">
                                                    <label class="navrotalabel">
                                                        {{deneyim.name}}</label>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <img class="navrotaimg"
                                                     src="{{envire.crmimages}}{{deneyim.resimId}}"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li ngbDropdown class="nav-item dropdown-user" style="    padding: 15px 3px;">
                            <a class="nav-link dropdown-user-link" id="dropdown-user4"
                               id="navbarUserDropdown4" aria-haspopup="true" aria-expanded="false"
                               style="justify-content: center; text-align: center">
                                <ng-container *ngIf="this.currentUser">VARLIKLARIMIZ
                                </ng-container>
                            </a>
                            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown4" class="navbardropdown full-menu"
                                 style="max-height: fit-content;">
                                <div class="row p-5">
                                    <div class="col-2 m-auto" style="margin-bottom: 15px !important;"
                                         *ngFor="let deneyim of navbarvarliklarimiz; let i=index;"
                                         (click)="getVarlikOfEtikets(deneyim)">
                                        <div class="row experience">
                                            <div class="col-12" style="z-index: 999;position: absolute;">
                                                <div class="dv1">
                                                    <label class="navrotalabel">
                                                        {{deneyim.name}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <img class="navrotaimg"
                                                     src="{{envire.crmimages}}{{deneyim.resimId}}"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li ngbDropdown class="nav-item dropdown-user" style="    padding: 15px 3px;">
                            <a class="nav-link dropdown-user-link" id="dropdown-user5"
                               id="navbarUserDropdown5" aria-haspopup="true" aria-expanded="false"
                               style="justify-content: center; text-align: center">
                                <ng-container *ngIf="this.currentUser">MEDYA
                                </ng-container>
                            </a>
                            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown4" class="navbardropdown full-menu"
                                 style="max-height: fit-content;">
                                <div class="row p-5" style="justify-content: center;">
                                    <div class="col-2 " style="margin-bottom: 15px !important;"
                                         (click)="goVoicePage()" *ngIf="voicecount>0">
                                        <div class="row experience" style="flex-direction: column-reverse;">
                                            <div class="col-12">
                                                <img src="assets/voices.jpg" class="navrotaimg"
                                                     style="border:0px solid gray; border-radius:8px;"/>

                                            </div>
                                            <div class="col-12" style="z-index: 999;position: absolute;">
                                                <div class="dv1">
                                                    <label class="navrotalabel">
                                                        Sesler
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 " style="margin-bottom: 15px !important;"
                                         (click)="goPhotoPage()">
                                        <div class="row experience" style="flex-direction: column-reverse;">
                                            <div class="col-12">
                                                <img src="assets/foto.jpg"
                                                     class="navrotaimg"
                                                     style="border:0px solid gray; border-radius:8px;"/>

                                            </div>
                                            <div class="col-12" style="z-index: 999;position: absolute;">
                                                <div class="dv1">
                                                    <label class="navrotalabel">
                                                        Fotoğraf
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-2 " style="margin-bottom: 15px !important;"
                                         (click)="goVideoPage()">
                                        <div class="row experience" style="flex-direction: column-reverse;">
                                            <div class="col-12">
                                                <img src="assets/video.jpg"
                                                     class="navrotaimg"
                                                     style="border:0px solid gray; border-radius:8px;"/>

                                            </div>
                                            <div class="col-12" style="z-index: 999;position: absolute;">
                                                <div class="dv1">
                                                    <label class="navrotalabel">
                                                        Video
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-3 d-lg-block d-none align-self-center">
                <ul class="navbar-nav mb-z2 mb-lg-0 ">
                    <li class="pad15 harita" style="cursor: pointer;">
                        <div (click)="goMapAtlas()" class="ttmable">
                            <label style="margin-bottom: 2px;color: #000;font-family: serif;font-weight: 600; cursor: pointer ;
                            font-family: 'Montserrat', sans-serif !important;">
                                Dijital Gezi
                            </label>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"
                                 fill="none" style="margin: 6px 4px 7px 8px;width: 22px;height: 22px;"
                                 stroke-linejoin="round" class="feather feather-map headermapicon"
                                 stroke="#007bff" stroke-width="2" stroke-linecap="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                            </svg>
                        </div>
                    </li>
                    <li class="pl-2f pad15" style="margin-top: 4px ;cursor: pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"
                             fill="none" style="margin-right: 5px;"
                             stroke="currentColor"
                             stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-headphones"
                             (click)="displaychatbox()">
                            <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                            <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                        </svg>
                    </li>
                    <li class="pad15 harita">
                        <a (click)="goMap()">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 class="feather feather-map headermapicon">
                                <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                <line x1="8" y1="2" x2="8" y2="18"></line>
                                <line x1="16" y1="6" x2="16" y2="22"></line>
                            </svg>
                        </a>
                    </li>
                    <li ngbDropdown class="nav-item dropdown-notuser mobile-nav" *ngIf="girisyapan()">
                        <button class="btn btn-main" id="gfg" *ngIf="girisyapan()">
                            {{ this.currentUser.firstName }} {{ this.currentUser.lastName }}
                        </button>
                        <div ngbDropdownMenu="gfg" class="dropdown-menu-right" style="top: 46px !important">
                            <button style="width: 100%" ngbDropdownItem (click)="openGirisComponent()">Giriş Yap
                            </button>
                            <button style="width: 100%;" ngbDropdownItem routerLink="register">Üye ol</button>
                        </div>
                    </li>
                    <li ngbDropdown class="nav-item dropdown-user2" *ngIf="!girisyapan()" style="padding: 7px 3px;">
                        <a class="nav-link dropdown-user-link" id="dropdown-user"
                           id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="this.currentUser">
                                <!--<div class="user-nav d-sm-flex d-none">
                                    <span class="user-name font-weight-bolder">
                                    {{ this.currentUser.firstName }} {{ this.currentUser.lastName }}</span>
                                </div>-->
                                <span class="avatar">
                                        <img class="round" alt="avatar" height="33" width="33"
                                             src="assets/images/portrait/small/default_user.png"/>
                                         <span class="avatar-status-online"></span>
                                    </span>
                            </ng-container>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarUserDropdown"
                             class="dropdown-menu dropdown-menu-right"
                             style="margin-left: -15px; top: 64px !important;">
                            <!--<a ngbDropdownItem routerLink="administration" *ngIf="currentUser?.type == 'admin'">
                                <span [data-feather]="'settings'" [class]="'mr-50 red'"></span>Yöneticilik
                            </a>-->
                            <!-- <a ngbDropdownItem (click)="openProfileModal(profileModal)">
                                <span [data-feather]="'user'" [class]="'mr-50'"></span>Profil
                            </a> -->
                            <a ngbDropdownItem routerLink="favourite-varliklar">
                                <span [data-feather]="'heart'" [class]="'mr-50'"></span>Favori Varlıklar
                            </a>
                            <a ngbDropdownItem (click)="logout()">
                                <span [data-feather]="'power'" [class]="'mr-50'"></span>Çıkış Yap
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<div *ngIf="!chtboxhide" class="chatdivV2">
    <div class="container">
        <div class="row">
            <div class="col-12 text-right pb-2">
                <div class="pb-2" style="border-bottom: 1px solid #cccccc80">
                    <a style="color: #fff;"
                       (click)="displaychatbox()">
                        <i data-feather="x" class="font-medium-3"></i>
                    </a>
                </div>

            </div>
            <div class="col-12">
                <div class="qyubi" *ngFor="let cht of chatboxlist; let i=index">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="cht.type==='qyubi'">
                                <div *ngIf="cht.text1!=''" class="text1">{{cht.text1}}</div>
                                <div *ngIf="cht.text2!=''" class="text2">{{cht.text2}}</div>
                                <div class="oneributtondiv">
                                    <button class="oneributton" *ngFor="let kon of cht.oneri;" disable="cht.disabled"
                                            (click)="secilenetiket(i,cht,kon)"
                                            [disabled]="cht.disabled">{{kon.name}}</button>
                                </div>
                                <button *ngIf="cht.dahafazla" class="dahafazla"
                                        style="margin-left: 20%;width: 60%;padding: 6px;"
                                        (click)="finish(cht)">
                                    Daha Fazla Özelleştirme
                                </button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="cht.type==='user'" class="secimleriniz">
                                {{cht.secimleriniz}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="cht.type==='result'">
                                <div class="resultdiv" style="text-decoration: unset;">Arama Kriterine Göre
                                    Varlıklarımız;
                                </div>
                                <div class="resultdiv" style="font-weight: 400; width: auto; float: left;"
                                     *ngFor="let res of cht.result; let in=index;">
                                    <label class="label23 labelvarlik" (click)="goVarlikDetail(res)">
                                        {{res.name}}
                                    </label>
                                    <!--
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         style="width: 10%;display: flow-root;float: left;margin: 4px;"
                                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                         class="feather feather-arrow-right">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>-->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="cht.type==='resultbos'">
                                <div class="resultdiv" style="text-decoration: unset;">{{cht.text1}}</div>
                                <div class="resultdiv"
                                     style="font-weight: 400; width: auto; float: left;">{{cht.text2}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="inputdiv" style="border-top: 1px solid #cccccc80">
                    <ng-select style="width: 100%;"
                               [items]="randomEtiketler"
                               [multiple]="false"
                               [closeOnSelect]="true"
                               [searchable]="true"
                               bindLabel="name"
                               placeholder="Etiket Seçiniz!"
                               [(ngModel)]="searchsecilietiket"
                               notFoundText="Etiket Listesi Boş"
                               (change)="changeEtiket(chatboxlist[chatboxlist.length-1],searchsecilietiket)">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div *ngIf="!chtboxhide">
<div class="chatdiv" id="chatdivid">
    <a style="float: right;text-align: right;width: 100%;display: flow-root;margin-bottom: -20px;color: #fff;"
       (click)="displaychatbox()">
        <i data-feather="x" class="font-medium-3"></i>
    </a>
    <div class="messagerow" *ngFor="let message of messages; let i = index">
        <div class="{{message.type}}">
            <p class="secimler">
                <span *ngIf="varlikgeldi && i==messages.length-1"> Sonuçlar:</span>
                {{message.content}}
            </p>
            <div class="buttonlar">
                <button *ngFor="let button of message.buttons" #suggest (click)="buttonClick(suggest.title,i)"
                        title="{{button}}"
                        type="button"># {{button}}</button>
            </div>
        </div>
        <button #nosuggest (click)="buttonClick(nosuggest.title,i)" title="NoSuggest" type="button"
                class="dahafazla"
                *ngIf="message.buttons.length > 0 && i>0">
            <span>Daha fazla özelleştirme istemiyorum</span>
        </button>
    </div>

    <div class="inputdiv">
        <input #textbox type="text" id="textbox" name="textbox" class="inputuser">
        <button type="button" (click)="typeClick(textbox.value)" class="userinputbutton">Gönder</button>
    </div>
</div>
</div>
-->


<!-- Mobil Menü -->
<nav id="nav" class="navbar navbar-expand-lg bg-white w-100 p-0" *ngIf="mobileMenu" style="z-index: 9999;">
    <div class="container for-mobil d-block">
        <div class="row">
            <div class="col-12 d-lg-none d-block text-left vh-100 mobil-menu">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="row" style="background-color: #09275c;height: 80px;">
                            <div class="col-12 align-self-center">
                                <img (click)="menuOpenOrClose()" [routerLink]="'/'"
                                     style="width: calc(100% - 110px);background-color: #fff;padding: 3px 13px;border-radius: 7px;"
                                     src="assets/images/logo/km-kultur-logo.png" width="200px">
                            </div>
                            <div class="col-2 position-fixed align-self-center text-right" style="color: #fff">
                                <div (click)="menuOpenOrClose()">
                                    <i [data-feather]="'x'" class="font-large-1"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="/">Anasayfa</a>
                            </li>
                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownToggle class="nav-link" id="navbarDropdownDistrict" aria-haspopup="true"
                                   aria-expanded="false">
                                    <ng-container>
                                        İlçelerimiz
                                    </ng-container>
                                </a>

                                <div ngbDropdownMenu aria-labelledby="navbarDropdownDistrict" class="dropdown-menu">
                                    <div class="row px-5 py-3 bg-light-2">
                                        <div class="col-6 district">
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.5879578&y=36.8831883">Oniksişubat</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.5789825&y=36.9695747">Dulkadiroğlu</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.5746445&y=36.3531017">Andırın</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.3841023&y=36.8572468">Türkoğlu</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.9654009&y=37.448205">Nurhak</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=38.0600276&y=37.1887265">Ekinözü</a>
                                        </div>
                                        <div class="col-6 district">
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=37.7445878&y=37.2958773">Çağlayancerit</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=38.2075617&y=37.2016077">Elbistan</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=38.2075617&y=37.2016077">Elbistan</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=38.2462643&y=36.920267">Afşin</a>
                                            <a (click)="menuOpenOrClose()" class="dropdown-item"
                                               href="/map?type=ilce&x=38.0214051&y=36.4984414">Göksun</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownToggle class="nav-link" id="navbarDropdownRoute" aria-haspopup="true"
                                   aria-expanded="false">
                                    <ng-container>
                                        Gezi Rotalarımız
                                    </ng-container>
                                </a>

                                <div ngbDropdownMenu aria-labelledby="navbarDropdownRoute" class="dropdown-menu">
                                    <div class="row px-5 py-3 bg-light-2">
                                        <div class="col-12" *ngFor="let rota of _sureliRota; let i=index;"
                                             (mouseenter)="rotamouseover(rota)" (click)="menuOpenOrClose()">
                                            <div class="row mx-0 nav-route">
                                                <div class="col-5">
                                                    <a href="/rota-detay?id={{rota.id}}">
                                                        <img width="100%" height="70px"
                                                             src="{{envire.crmimages}}{{rota.pictureId}}"
                                                             style="border-radius: 10px"
                                                        />
                                                    </a>
                                                </div>
                                                <div class="col-7 align-self-center">
                                                    <label (click)="goRotaDetail(rota)" class="navrotalabel">
                                                        {{rota.name}}</label>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                         viewBox="0 0 24 24"
                                                         fill="none"
                                                         stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                         stroke-linejoin="round"
                                                         class="feather feather-map navsvgrotas" style="float: right;"
                                                         (click)="goMapRota(rota)">
                                                        <polygon
                                                                points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                                        <line x1="8" y1="2" x2="8" y2="18"></line>
                                                        <line x1="16" y1="6" x2="16" y2="22"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownToggle class="nav-link" id="navbarDropdownExperimentation"
                                   aria-haspopup="true" aria-expanded="false">
                                    <ng-container>
                                        Deneyimlerimiz
                                    </ng-container>
                                </a>

                                <div ngbDropdownMenu aria-labelledby="navbarDropdownExperimentation"
                                     class="dropdown-menu">
                                    <div class="row px-5 py-3 bg-light-2">
                                        <div class="col-6 pb-3 m-auto"
                                             *ngFor="let deneyim of deneyimler; let i=index;"
                                             (click)="getVarlikOfEtikets(deneyim)">
                                            <div class="row experience">
                                                <div class="col-12" style="z-index: 999;position: absolute;">
                                                    <div class="dv1">
                                                        <label class="navrotalabel">
                                                            {{deneyim.name}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <img class="navrotaimg"
                                                         src="{{envire.crmimages}}{{deneyim.resimId}}"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownToggle class="nav-link" id="navbarDropdownExperimentation"
                                   aria-haspopup="true" aria-expanded="false">
                                    <ng-container>
                                        Varlıklarımız
                                        <!---BURA1--->
                                    </ng-container>
                                </a>

                                <div ngbDropdownMenu aria-labelledby="navbarDropdownExperimentation"
                                     class="dropdown-menu">
                                    <div class="row px-5 py-3 bg-light-2">
                                        <div class="col-6 pb-3 m-auto"
                                             *ngFor="let deneyim of navbarvarliklarimiz; let i=index;"
                                             (click)="getVarlikOfEtikets(deneyim)">
                                            <div class="row experience">
                                                <div class="col-12" style="z-index: 999;position: absolute;">
                                                    <div class="dv1">
                                                        <label class="navrotalabel">
                                                            {{deneyim.name}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <img class="navrotaimg"
                                                         src="{{envire.crmimages}}{{deneyim.resimId}}"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownToggle class="nav-link" id="navbarDropdownMedya"
                                   aria-haspopup="true" aria-expanded="false">
                                    <ng-container>
                                        Medya
                                    </ng-container>
                                </a>
                                <!---BURASIIIIII--->
                                <div ngbDropdownMenu aria-labelledby="navbarDropdownMedya"
                                     class="dropdown-menu">
                                    <div class="row px-5 py-3 bg-light-2">
                                        <div class="col-6 pb-3 m-auto"
                                             (click)="goVoicePage()" *ngIf="voicecount>0">
                                            <div class="row experience">
                                                <div class="col-12" style="z-index: 999;position: absolute;">
                                                    <div class="dv1">
                                                        <label class="navrotalabel">
                                                            Sesler</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <img class="navrotaimg"
                                                         src="assets/voices.jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pb-3 m-auto"
                                             (click)="goPhotoPage()" *ngIf="voicecount>0">
                                            <div class="row experience">
                                                <div class="col-12" style="z-index: 999;position: absolute;">
                                                    <div class="dv1">
                                                        <label class="navrotalabel">
                                                            Fotoğraflar</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <img class="navrotaimg"

                                                         src="assets/foto.jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pb-3 m-auto"
                                             (click)="goVideoPage()">
                                            <div class="row experience">
                                                <div class="col-12" style="z-index: 999;position: absolute;">
                                                    <div class="dv1">
                                                        <label class="navrotalabel">
                                                            Video</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <img class="navrotaimg"
                                                         src="assets/video.jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" (click)="goMap()">
                                    <span>Harita </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none"
                                         stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round"
                                         class="feather feather-map headermapicon">
                                        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                        <line x1="8" y1="2" x2="8" y2="18"></line>
                                        <line x1="16" y1="6" x2="16" y2="22"></line>
                                    </svg>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" (click)="goMapAtlas()">
                                    <span>Dijital Gezi</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"
                                         fill="none" style="margin: 6px 4px 7px 8px;width: 22px;height: 22px;"
                                         stroke-linejoin="round" class="feather feather-map headermapicon"
                                         stroke="#007bff" stroke-width="2" stroke-linecap="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                        <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="!girisyapan()">
                                <a class="nav-link" (click)="logout()">
                                    <span>Çıkış Yap </span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

