import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input() layout = 'vertical';

  @Input() menu: any;
  @Input() productMenu! : boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, 
              private _coreMenuService: CoreMenuService,
              private _authenticationService : AuthenticationService) {
    // Set the private defaults
    this.currentUser = _authenticationService.currentUser
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    // console.log(this.menu);
    //console.log("core-menu-product-menu:",this.menu);
    
    if(this.productMenu) this.layout = "horizontal"
    // if(!this.productMenu){
    //   this.menu = this._coreMenuService.getCurrentMenu();
    // }

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      // this.menu = this._coreMenuService.getCurrentMenu();
      if(!this.productMenu){
        this.menu = this._coreMenuService.getCurrentMenu();
      }else{
        this.layout = "horizontal"
      }

      this._changeDetectorRef.markForCheck();
    });
  }
}
