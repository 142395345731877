import { Injectable } from '@angular/core';
import { AuthenticationService } from "./authentication.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";
import { Product } from "../models/entity/product";
import { Brand } from 'app/models/entity/brand';
import { Marka } from 'app/models/entity/marka';
import { Varlik } from 'app/models/entity/varlik';

@Injectable({
    providedIn: 'root'
})
export class VarlikService extends BaseService<Varlik> {

    constructor(public _authenticationService: AuthenticationService,
        public _http: HttpClient) {
        super(_authenticationService, _http, environment.varlik);
    }

    getListForHomePage() {
        return this.getListByWhere(null, "where[0][type]=isTrue&where[0][attribute]=populer")
    }
    getListByUserId(categoryId: string) {
        let where = `where[0][type]=equals&where[0][attribute]=createdById&where[0][value]=${categoryId}`;
        return this.getListByWhere("", where);
    }

    getProductsWithSameNameAndBrand(product: Product) {
        let where = `where[0][type]=equals&where[0][attribute]=name&where[0][value]=${product.name}`;
        where += `&where[1][type]=equals&where[1][attribute]=markaId&where[1][value]=${product.markaId}`;

        return this.getListByWhere("", where);
    }

    getProductsByBrandId(brandId: string) {
        let where = `where[0][type]=equals&where[0][attribute]=markaId&where[0][value]=${brandId}`;
        return this.getListByWhere("", where);
    }

    getListForAccountForFilter(filter: any, filterObject: any, offset: number, maxSize: number, firmaId: string, markaId: string) {
        let whereQuery = '';
        let whereCounter = 0;

        whereQuery += `&where[${whereCounter}][type]=equals&where[${whereCounter}][attribute]=accountId&where[${whereCounter}][value]=${firmaId}`;
        whereCounter++;

        if (Object.keys(filterObject).some(k => !!filterObject[k])) {
            let search = filterObject['search'];
            let product = filter['product'];

            if (search && search != '') {
                whereQuery += `&where[${whereCounter}][type]=textFilter&where[${whereCounter}][value]=%25${search}%25`;
                whereCounter++;
            }
            if (!!product?.id) {
                whereQuery += `${whereCounter == 0 ? "" : "&"}where[${whereCounter}][type]=equals&where[${whereCounter}][attribute]=id`;
                whereQuery += `&where[${whereCounter}][value]=${product.id}`
                whereCounter++;
              }
            }
            return this.getListWithSelect(whereQuery, offset, maxSize)
        }

    getListForFilter(filterObject: any, offset: number, maxSize: number, selectedRootCategory?: any) {

        let whereQuery = "";
        let whereCounter = 0;
        const { textFilter, selectedBrands, selectedCategories, brands, subcategories } = filterObject;

        const usedBrands = selectedBrands?.length > 0 ? selectedBrands : brands;
        const usedCategories = Object.assign([], (selectedCategories?.length > 0 ? selectedCategories : subcategories));
        if (selectedRootCategory) {
            usedCategories.push(selectedRootCategory);
        }
        // console.log("usedBrands : " , usedBrands);
        // console.log("usedCategories : " , usedCategories);


        if (!!textFilter) {
            whereQuery += `&where[${whereCounter}][type]=textFilter&where[${whereCounter}][value]=%25${textFilter}%25`;
            whereCounter++;
        }
        if (!!usedBrands?.length) {
            whereQuery += `&where[${whereCounter}][type]=in&where[${whereCounter}][attribute]=markaId`
            usedBrands?.forEach(brand => {
                whereQuery += `&where[${whereCounter}][value][]=${brand.id}`
            });
            whereCounter++;
        }
        if (!!usedCategories?.length) {
            whereQuery += `&where[${whereCounter}][type]=in&where[${whereCounter}][attribute]=categoryId`
            usedCategories?.forEach(category => {
                whereQuery += `&where[${whereCounter}][value][]=${category.id}`
            });
            whereCounter++;
        }

        return this.getListWithSelect(whereQuery, offset, maxSize)
    }

    getVarlikByEtiketId(etiketId: string) {

     let where = `where[0][type]=linkedWith&where[0][attribute]=etikets&where[0][value]=${etiketId}`;
     
     return this.getListByWhere(environment.varlikSelect, where);
 }

}
