import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

  transform(text: string , searchText : string): string {
    if(!searchText) return text;
    return text.replace(new RegExp(searchText, "gi"), (match) => `<span class="highlighted">${match}</span>`);
  }
}