import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreLoadingScreenService {
  loadingScreenEl: any;
  animationPlayer: AnimationPlayer;

  constructor(
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private _router: Router,
    private _animationBuilder: AnimationBuilder
  ) {
    this._init();
  }

  private _init(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadingScreenEl = this._document.body.querySelector('#loading-bg');
    }

    if (this.loadingScreenEl) {
      this._router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => {
          setTimeout(() => {
            this.hide();
          });
        });
    }
  }

  show(): void {
    if (!this.loadingScreenEl) return; // Element yoksa geri dön

    this.animationPlayer = this._animationBuilder
      .build([
        style({ opacity: '0', zIndex: '99999' }),
        animate('250ms ease', style({ opacity: '1' }))
      ])
      .create(this.loadingScreenEl);

    setTimeout(() => {
      this.animationPlayer.play();
    }, 0);
  }

  hide(): void {
    if (!this.loadingScreenEl) return; // Element yoksa geri dön

    this.animationPlayer = this._animationBuilder
      .build([
        style({ opacity: '1' }),
        animate('250ms ease', style({ opacity: '0', zIndex: '-10' }))
      ])
      .create(this.loadingScreenEl);

    this.animationPlayer.onDone(() => {
      this.loadingScreenEl.style.display = 'none'; // Elementi gizle
    });

    setTimeout(() => {
      this.animationPlayer.play();
    }, 0);
  }
}
